import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractWindow} from '../../../core/abstract/AbstractWindow';
import {UtilsService} from '../../../core/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from '../../../core/services/SnackbarService';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {BookingForGuest, TeammateInCharge} from '../../../models/guestview/BookingForGuest';
import {CoHostInCharge, PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {Corporate} from '../../../models/guestview/Corporate';
import {CorporateBookletSection} from '../../../models/CorporateBookletSection';
import {Poi} from '../../../models/Poi';
import {PersonForGuest} from '../../../models/guestview/PersonForGuest';

@Component({
  selector: 'app-g4-booking-details',
  templateUrl: './g4-booking-details.component.html',
  styleUrls: ['./g4-booking-details.component.scss']
})
export class G4BookingDetailsComponent extends AbstractWindow implements OnInit, OnChanges {

  language = 'fr';
  @Input() booking: BookingForGuest;
  @Input() property: PropertyForGuest;
  @Input() corporate: Corporate;
  @Input() section: CorporateBookletSection;
  centerOfTheMap: Poi;
  propertyPoi: Poi;
  showingMoreDescription = false;

  constructor(
              public utilsService: UtilsService,
              public translateService: TranslateService,
              public snackBarService: SnackbarService,
              public router: Router,
              protected dialog: MatDialog) {
    super(utilsService);
  }

  ngOnInit() {
    this.language = this.translateService.currentLang;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.property && this.property) {
      if (this.property.poi) {
        this.centerOfTheMap = new Poi();
        this.centerOfTheMap.x = this.property.poi.x;
        this.centerOfTheMap.y = this.property.poi.y
        this.propertyPoi = this.property.poi;
      }
    }
  }

  findDirection() {
    const distGps = this.property.poi.y + ',' + this.property.poi.x;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;

        const currentGps = lat + ',' + long;
        this.utilsService.getDirection(currentGps, distGps);
      }, err => {
        this.utilsService.openToPosition(distGps);
      });
    } else {
      this.utilsService.openToPosition(distGps);
    }
  }

  counter(type: 'travelers' | 'beds' | 'baths' | 'doublebeds' | 'singlebeds' | 'sofabeds' | 'shower' | 'bedroom') {
    switch (type) {
      case 'travelers':
        const travelersCount = this.booking.adults + this.booking.children + this.booking.babies;
        return travelersCount > 0 ? travelersCount : (this.booking.guests ? this.booking.guests.length : 0);

      case 'beds':
        return this.property.doubleBeds + this.property.singleBeds + this.property.sofaBeds + this.property.babyBeds;
      case 'doublebeds':
        return this.property.doubleBeds;
      case 'singlebeds':
        return this.property.singleBeds;
      case 'bedroom':
        return this.property.bedrooms;
      case 'sofabeds':
        return this.property.sofaBeds;
      case 'baths':
        return this.property.bathrooms;
      case 'shower':
        return this.property.showerRooms;
    }
  }

  emailPerson(use: PersonForGuest | TeammateInCharge | CoHostInCharge) {
    document.location.href = 'mailto:' + use.email;
  }
  callTeammate(use: TeammateInCharge | CoHostInCharge) {
    document.location.href = 'tel:' + use.phoneNumber;
  }
  call(host: PersonForGuest | undefined) {
    if (!host) {
      return;
    }
    document.location.href = 'tel:' + host.phone;
  }

  getLanguage(code: string) {
    return UtilsService.getLanguageFlag(code);
  }


  getCorrectLink(webSiteUrl: string) {
    if (!webSiteUrl) {
      return null;
    }
    if (webSiteUrl.startsWith('http')) {
      return webSiteUrl;
    }

    return 'http://' + webSiteUrl;
  }
}
