import {Component, Inject, Input, OnInit} from '@angular/core';
import {Guide} from '../../../models/Guide';
import {PropertyForGuest} from '../../../models/guestview/PropertyForGuest';
import {BookingForGuest} from '../../../models/guestview/BookingForGuest';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UtilsService} from '../../../core/services/utils.service';
import {Corporate} from '../../../models/guestview/Corporate';
import {GuestUpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/GuestUpSellOnBoarding';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {ApiService} from '../../../core/http/api.service';
import {UpSellOnBoarding} from '../../../models/V3_UpSellOnboarding/UpSellOnBoarding';

@Component({
  selector: 'app-m20-upsell-details',
  templateUrl: './m20-upsell-details.component.html',
  styleUrls: ['./m20-upsell-details.component.scss']
})
export class M20UpsellDetailsComponent implements OnInit {

  currentQty: number = 1;
  currentDate: string;
  guide: Guide;
  property: PropertyForGuest;
  booking: BookingForGuest;
  corporate: Corporate;
  guestUpSellOnBoarding: GuestUpSellOnBoarding;
  currentPictureUrl: string = null;
  currentUpSellOnBoarding: UpSellOnBoarding;
  constructor(public utilsService: UtilsService,
              private translateService: TranslateService,
              private location: Location,
              private apiService: ApiService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<M20UpsellDetailsComponent>) { }

  ngOnInit() {
    console.log("this.data", this.data);
    if (this.data && this.data.guide) {
      this.guide = this.data.guide;
      if (this.guide.upSellSection != null && this.guide.upSellSection.needQty) {
        // this.guide.upSellSection.relatedQty = 1;
        if (this.guide.upSellSection.relatedQty) {
          this.currentQty = this.guide.upSellSection.relatedQty;
        }
      }

      if (localStorage.getItem("guestUpSellOnBoarding") != null) {
        this.guestUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
        if (this.guestUpSellOnBoarding.guides.filter(g => g.guideId === this.guide.guideId).length > 0) {
          this.currentQty = this.guestUpSellOnBoarding.guides.filter(g => g.guideId === this.guide.guideId)[0].upSellSection.relatedQty;
        }
      }

    }
    if (this.data && this.data.booking) {
      this.booking = this.data.booking;
      this.currentDate = this.booking.startDate;
    }
    if (this.data && this.data.corporate) {
      this.corporate = this.data.corporate;
    }
    if (this.data && this.data.property) {
      this.property = this.data.property;
    }
    if (this.data && this.data.currentUpSellOnBoarding) {
      this.currentUpSellOnBoarding = this.data.currentUpSellOnBoarding;

    }

  }


  addUpSellOnboarding(guide: Guide, qty: number = 0) {
    if (localStorage.getItem("guestUpSellOnBoarding") != null) {
      this.guestUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
      if (this.booking.id != this.guestUpSellOnBoarding.propertyBookingId) {
        localStorage.removeItem("guestUpSellOnBoarding");
        this.resetGuestUpSellOnBoarding();
      }
    } else {
      this.resetGuestUpSellOnBoarding();
    }
    if (this.guestUpSellOnBoarding.guides == null) {
      this.guestUpSellOnBoarding.guides = [];
    }
    guide.upSellSection.relatedQty = this.currentQty;
    guide.upSellSection.chosenDate = this.currentDate;

    this.guestUpSellOnBoarding.guides = this.guestUpSellOnBoarding.guides.filter((object) => object.guideId !== guide.guideId );
    this.guestUpSellOnBoarding.guides.push(guide);
    localStorage.setItem("guestUpSellOnBoarding", JSON.stringify(this.guestUpSellOnBoarding));
    this.back();
  }

  checkIfUpsellItemExists(guideId: string) {
    let check = false;
    if (localStorage.getItem("guestUpSellOnBoarding") != null) {
      this.guestUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
      if (this.booking.id != this.guestUpSellOnBoarding.propertyBookingId) {
        localStorage.removeItem("guestUpSellOnBoarding");
      } else {
        if (this.guestUpSellOnBoarding.guides != null) {
          check = this.guestUpSellOnBoarding.guides.filter(g => g.guideId === guideId).length > 0;
          this.guide.upSellSection.relatedQty = 1;
          if (check && this.guide.upSellSection != null && this.guide.upSellSection.needQty) {
            this.guide.upSellSection.relatedQty = this.guestUpSellOnBoarding.guides.filter(g => g.guideId === guideId)[0].upSellSection.relatedQty;
          }
        }
      }
    }
    return check;
  }

  removeUpSellOnboarding(guide: Guide) {
    if (localStorage.getItem("guestUpSellOnBoarding") != null) {
      this.guestUpSellOnBoarding = JSON.parse(localStorage.getItem("guestUpSellOnBoarding"));
      if (this.booking.id != this.guestUpSellOnBoarding.propertyBookingId) {
        localStorage.removeItem("guestUpSellOnBoarding");
      } else {
        if (this.guestUpSellOnBoarding.guides != null) {
          this.guestUpSellOnBoarding.guides = this.guestUpSellOnBoarding.guides.filter((object) => object.guideId !== guide.guideId );
        }
      }
      if (this.guestUpSellOnBoarding.guides.length === 0) {
        localStorage.removeItem("guestUpSellOnBoarding");
      } else {
        localStorage.setItem("guestUpSellOnBoarding", JSON.stringify(this.guestUpSellOnBoarding));
      }
    }
    this.currentQty = 0;
    this.back();
  }

  resetGuestUpSellOnBoarding() {
    this.guestUpSellOnBoarding = new GuestUpSellOnBoarding();
    this.guestUpSellOnBoarding.propertyBookingId = this.booking.id;
    this.guestUpSellOnBoarding.language = this.translateService.currentLang;
    this.guestUpSellOnBoarding.guides = [];
    this.guestUpSellOnBoarding.returnUrl = this.location.path(true);
  }

  openLink(url: string) {
    this.apiService.upSellStatistics(this.property.id).subscribe( res => {
    });
    console.log("URL", url);
    UtilsService.openLinkInNewTab(url);
  }

  choosePicture(pictureUrl : string) {
    console.log("choposePicture", pictureUrl);
    if (this.currentPictureUrl === pictureUrl) {
      this.currentPictureUrl = null;
    } else {
      this.currentPictureUrl = pictureUrl;
    }
  }

  back() {
    this.dialogRef.close();
  }

  addQty(guide: Guide) {
    this.currentQty++;
  }
  removeQty(guide: Guide) {

    if (this.currentQty>0) {
      this.currentQty--;
    }

  }

}
